import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { collection, getDocs } from "firebase/firestore";
import { initAndGetFirestore } from './firebase';
import { SupportArticle } from './types/supportTypes';
import './supportArticlePage.css'

function SupportArticlePage() {
    const [article, setArticle] = useState<SupportArticle>({
        id: -1,
        title: "",
        content: "",
        category: "",
        lastUpdated: "",
    });

    async function getSingleSupportArticle(
        categoryIndex: number,
        articleIndex: number,
    ): Promise<SupportArticle> {
        const firestore = await initAndGetFirestore();
        const supportCollection = collection(firestore, "support");

        return getDocs(supportCollection).then((snapshot) => {
            const title = snapshot.docs[categoryIndex].data().articles[articleIndex].title;
            const content = snapshot.docs[categoryIndex].data().articles[articleIndex].content;
            const category = snapshot.docs[categoryIndex].data().catName;
            const lastUpdated = snapshot.docs[categoryIndex].data().articles[articleIndex].lastUpdated;

            return {
                id: articleIndex,
                title: title,
                content: content,
                category: category,
                lastUpdated: lastUpdated.toDate().toDateString(),
            };
        });
    }

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const queryCategoryParam = query.get("category");
        const queryArticleParam = query.get("article");
        
        if (queryCategoryParam !== null && queryArticleParam != null) {
            const articleCategoryIndex = Number.parseInt(queryCategoryParam);
            const articleIndex = Number.parseInt(queryArticleParam);

            getSingleSupportArticle(articleCategoryIndex, articleIndex)
                .then((article) => {
                    setArticle(article);
                });
        }
    }, []);

    return (
        <div className="App">
            <div className="main-container">
                <div className="lev-card header-card support-header-card">
                    <h1 className="header-card-title">Support</h1>
                </div>
                <div className="lev-card" style={{paddingBottom: "30px"}}>
                    <div className="sa-title">
                        <Link to="/support">
                            <FontAwesomeIcon icon={faArrowCircleLeft} />
                        </Link>
                        <div className="sa-title-text">
                            <h1 className="sa-headline">{article.title}</h1>
                            <p className="sa-info">{article.category + " | Last updated: " + article.lastUpdated}</p>
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    <div className="sa-body">
                        <p className="sa-body-text">{article.content}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SupportArticlePage;