import React from 'react';
import ListItemText from '@material-ui/core/ListItemText';
import './Profile.css';

interface ProfileProps {
    image: string;
    name: string;
    role: string;
}

function Profile(props: ProfileProps) {
    return (
        <div className="profile-container">
            <div className="profile-image" style={{backgroundImage: `url(${props.image})`}}></div>
            <div className="profile-label">
                <ListItemText primary={props.name} secondary={props.role} />
            </div>
        </div>
    );
}

export default Profile;