import React, { Fragment, useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
// import AnnouncementBanner from './AnnouncementBanner';
import Home from './Home';
import Header from './Header';
import PrivacyPolicies from './privacyPolicies';
// import AppTerms from './appTerms';
import Support from './support';
import SupportArticlePage from './supportArticlePage';
import CookiesPage from './cookies';
import PageNotFound from './pageNotFound';
import Footer from './Footer';

function App() {
  let location = useLocation();

  useEffect(() => {
    // Only insert script on getlevitate.com, as we're using Cookiebot on getlevitate.co.uk
    if (!window.location.hostname.endsWith(".co.uk")) {
        var cookieProScript = document.createElement("script");
        cookieProScript.type = "text/javascript";
        cookieProScript.charset = "UTF-8";
        cookieProScript.dataset.domainScript = "b995b65f-39c3-47b3-aa2a-22565eea6be4";
        cookieProScript.src="https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js";

        const cookieProScriptsAlreadyAdded = document.head.getElementsByTagName("script");
        Array.from(cookieProScriptsAlreadyAdded)
            .filter(script => script.src.includes("cookiepro.com"))
            .forEach(script => {
            script.remove();
        });
        document.head.appendChild(cookieProScript);
    }
  }, [location]);

  return (
    <Fragment>
      {/* <AnnouncementBanner /> */}
      <Header />
      <main>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/privacy">
            <PrivacyPolicies />
          </Route>
          <Route exact path="/support">
            <Support />
          </Route>
          <Route path="/support/article">
            <SupportArticlePage />
          </Route>
          <Route path="/cookies">
            <CookiesPage />
          </Route>
          {/* <Route path="/terms">
            <AppTerms />
          </Route> */}
          <Route>
            <PageNotFound />
          </Route>
        </Switch>
        <Footer />
      </main>
    </Fragment>
  )
}

export default App;