import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core';
import { deepPurple } from '@material-ui/core/colors';
import { Helmet, HelmetProvider } from "react-helmet-async";
import './index.css';
import * as serviceWorker from './serviceWorker';
import App from './App';

const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: deepPurple[500],
      contrastText: "#ffffff",
    },
    secondary: {
      main: deepPurple['A100'],
      contrastText: "#000000"
    }
  },
  typography: {
    fontFamily: '"Orkney", "Roboto", "Helvetica", "Arial", sans-serif',
    h6: {
      fontFamily: '"Fredoka One", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: "26px",
      textAlign: 'center'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none'
      }
    },
    MuiDialog: {
      paper: {
        borderRadius: "15px",
        padding: "5px"
      },
    },
    MuiDialogTitle: {
      root: {
        textAlign: "center"
      }
    },
    MuiDialogContentText: {
      root: {
        textAlign: "start"
      }
    },
    MuiLinearProgress: {
      root: {
        borderRadius: "8px"
      }
    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={materialTheme}>
        <HelmetProvider>
          <Helmet>
            <script
              id="Cookiebot"
              src="https://consent.cookiebot.com/uc.js"
              data-cbid="1e20f24e-7500-4b1c-a67b-785fbec19139"
              data-blockingmode="auto"
              type="text/javascript"
            />
          </Helmet>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </HelmetProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
