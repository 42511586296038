import React, { ChangeEvent, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import ReCAPTCHA from "react-google-recaptcha";
import './Form.css';
import { FormField } from './types/formTypes';

interface FormProps {
  fields: FormField[],
  actionName: string,
  action: (fieldValues: string[]) => Promise<any>
}

interface FormState {
  fieldValues: string[];
  recaptcha: boolean;
  status: 'NOT_SUBMITTED' | 'SUBMITTING' | 'SUCCESS' | 'FAILED',
  fadeOutFooter: boolean;
}

function Form(props: FormProps) {
    const [state, setState] = useState<FormState>({
        fieldValues: Array(props.fields.length).fill(""),
        recaptcha: false,
        status: 'NOT_SUBMITTED',
        fadeOutFooter: false
    });
    const useStyles = makeStyles({
        "form-field": {
            width: "100%",
            margin: "0.5rem 0"
        }
    });
    const muiClasses = useStyles();
    const emailRegex = new RegExp('^[a-zA-Z0-9.!#$%&\'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$');

    function validateForm() {
        if (state.recaptcha !== true) {
            return false;
        }

        for (const fieldValue of state.fieldValues) {
            if (fieldValue === null || fieldValue === "" || (props.fields[state.fieldValues.indexOf(fieldValue)].type === 'Email' && emailRegex.test(fieldValue) === false)) {
                return false;
            }
        }

        return true;
    }

    function updateFieldValue(input: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, fieldIndex: number) {
        const newFieldValues = state.fieldValues;
        newFieldValues[fieldIndex] = input.currentTarget.value;
        setState({ ...state, fieldValues: newFieldValues });
    }

    function fadeOutFooter() {
        setState(currentState => ({ ...currentState, fadeOutFooter: true }));
    }

    function submitForm() {
        setState({ ...state, status: 'SUBMITTING' });
        props.action(state.fieldValues).then(() => {
            fadeOutFooter();
            setTimeout(() => {
                setState(currentState => ({ ...currentState, status: 'SUCCESS', fieldValues: Array(props.fields.length).fill(""), fadeOutFooter: false }));
            }, 500);
        }).catch((error) => {
            fadeOutFooter();
            setTimeout(() => {
                setState(currentState => ({ ...currentState, status: 'FAILED', fadeOutFooter: false }));
            }, 500)
        }).finally(() => {
            setTimeout(() => {
                fadeOutFooter();
                setTimeout(() => {
                    setState(currentState => ({ ...currentState, status: 'NOT_SUBMITTED', fadeOutFooter: false }));
                }, 500);
            }, 3000);
        });
    }

    return (
        <div className="form-area raised-card">
            {
                props.fields.map((field) => {
                    const fieldIndex = props.fields.indexOf(field);
                    return <TextField
                        className={muiClasses['form-field']}
                        key={field.label}
                        variant="outlined"
                        multiline={props.fields[fieldIndex].type === "TextArea"}
                        label={field.label}
                        helperText={field.helpText}
                        value={state.fieldValues[fieldIndex]}
                        onChange={(input) => updateFieldValue(input, fieldIndex)}
                    />
                })
            }
            <br></br>
            <div className="recaptcha-container">
                <ReCAPTCHA sitekey="6LcPIvAUAAAAAJ2i-m-FVQQqLd6jYXwoH9sIdMxH" onChange={(newValue) => setState({...state, recaptcha: newValue !== null})} />
            </div>
            <br></br>
            <div className={'form-footer' + (state.fadeOutFooter === true ? ' form-footer-fadeout' : '')}>
                {
                    state.status === 'SUCCESS'
                    ? <span className="form-result">
                        <FontAwesomeIcon className="form-success-icon" icon={faCheckCircle} />
                        <p>Sent!</p>
                    </span>
                    : state.status === 'FAILED'
                    ? <span className="form-result">
                        <FontAwesomeIcon className="form-failed-icon" icon={faCheckCircle} />
                        <p>Something went wrong</p>
                    </span>
                    : state.status === 'SUBMITTING'
                    ? <CircularProgress />
                    : <Button className="submit-button" variant="contained" disabled={!validateForm()} onClick={submitForm} color="secondary">
                        {props.actionName}
                    </Button>
                }
                {
                    state.status === 'FAILED' && <p className="form-failed-message">Try refreshing the page</p>
                }
            </div>
        </div>
    );
}

export default Form;