import React from 'react';
import { Link } from 'react-router-dom';
import TwitterLogo from './images/twitterlogo.png';
import FbLogo from './images/fblogo.png';
import IgLogo from './images/iglogo.png';
import './Footer.css';

function Footer() {
    return (
        <div className="lev-card footer-card">
            <p className="footer-legal">© 2023 Snoboard Ltd, a company registered in Scotland at 48 West George St, Suite 2/3, Glasgow, G2 1BP (company no. SC709958)</p>
            <div>
                {/* <Link to="/terms">App Terms</Link>
                <p className="footer-separater"> | </p> */}
                <Link to="/privacy">Privacy</Link>
                <p className="footer-separater"> | </p>
                <Link to="/cookies">Cookies</Link>
            </div>
            <a className="social-logos" href="https://twitter.com/getlevitateapp" target="_blank" rel="noopener noreferrer">
                <img src={TwitterLogo} height="50px" width="50px" alt="Twitter Logo"></img>
            </a>
            <a className="social-logos" href="https://facebook.com/getlevitateapp" target="_blank" rel="noopener noreferrer">
                <img src={FbLogo} height="50px" width="50px" alt="Facebook Logo"></img>
            </a>
            <a className="social-logos" href="https://instagram.com/golevitateapp" target="_blank" rel="noopener noreferrer">
                <img src={IgLogo} height="50px" width="50px" alt="Instagram Logo"></img>
            </a>
        </div>
    )
}

export default Footer;