import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously } from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDckOeaA9ofSGDOW2yGCMVeTv0Y8dtvs6c",
  authDomain: "levitate-ebb54.firebaseapp.com",
  databaseURL: "https://levitate-ebb54.firebaseio.com",
  projectId: "levitate-ebb54",
};

const firebaseApp = initializeApp(firebaseConfig);

const auth = getAuth(firebaseApp);
const functions = getFunctions(firebaseApp);
const firestore = getFirestore(firebaseApp);

async function initAndGet<InstanceType>(instance: InstanceType) {
  if (auth.currentUser === null) {
    await signInAnonymously(auth);
  }

  return instance;
}

export const initAndGetFunctions = () => initAndGet(functions);
export const initAndGetFirestore = () => initAndGet(firestore);
