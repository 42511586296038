import React, { Fragment, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import HeaderLogo from './images/headerLogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation, faBars, faHome, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import './Header.css';

interface GetHelpOrg {
    name: string;
    description: string;
    url: string;
}

interface HeaderState {
    helpDialogOpen: boolean;
    drawerOpen: boolean;
}

function Header() {
    const [state, setState] = useState<HeaderState>({
        helpDialogOpen: false,
        drawerOpen: false
    });
    const router = useHistory();
    const isNarrowScreen = useMediaQuery('(max-width: 450px)');
    const isPortrait = useMediaQuery('(orientation: portrait)');
    const useStyles = makeStyles({
        "gethelp-title": {
            '& :first-child': {
                fontSize: isNarrowScreen === true ? '22px' : undefined
            }
        },
        "gethelp-org": {
            paddingRight: '112px'
        },
        "gethelp-org-text": {
            '& :first-child': {
                fontSize: isNarrowScreen === true ? '0.9rem' : undefined
            },
            '& :last-child': {
                fontSize: isNarrowScreen === true ? '0.8rem' : undefined
            }
        },
        "gethelp-org-divider": {
            height: '2px'
        },
        "header-button": {
            color: "white",
            fontSize: "25px"
        },
        "menu-button": {
            display: isPortrait === true ? "inline-flex" : "none",
            marginRight: isPortrait === true ? "5px" : undefined
        },
        "link-button": {
            display: isPortrait === true ? "none" : undefined,
            fontSize: "18px"
        }
    });
    const muiClasses = useStyles();

    const helpOrgs: GetHelpOrg[] = [
        {
            name: "Samaritans",
            description:
                "Always there to listen and help across the UK and Ireland",
            url: "https://www.samaritans.org"
        },
        {
            name: "Mind",
            description: "Mental wellbeing support for people in England and Wales",
            url: "https://www.mind.org.uk"
        },
        {
            name: "Togetherall",
            description: "Chat to others going through the same as you",
            url: "https://togetherall.com"
        },
        {
            name: "Childline",
            description: "Supporting children and young people under 19",
            url: "https://www.childline.org.uk"
        },
        { name: "divider", description: "", url: "" },
        {
            name: "Breathing Space",
            description: "Mental wellbeing helpline for Scotland",
            url: "https://breathingspace.scot"
        },
        {
            name: "C.A.L.L.",
            description: "Mental wellbeing helpline for Wales",
            url: "http://www.callhelpline.org.uk"
        },
        {
            name: "Lifeline",
            description: "Mental wellbeing helpline for Northern Ireland",
            url: "https://www.lifelinehelpline.info"
        },
        {
            name: "50808",
            description: "Mental wellbeing helpline for Ireland",
            url: "https://text50808.ie"
        },
    ];

    function onHomeButton() {
        router.push("/");
    }

    // function onBlogButton() {
    //     window.open("https://medium.com/levitate-for-mental-wellbeing");
    // }
    
    function onSupportButton() {
        router.push("/support");
    }

    function openGetHelpDialog() {
        setState({ ...state, helpDialogOpen: true });
    }

    function closeGetHelpDialog() {
        setState({ ...state, helpDialogOpen: false });
    }

    function openDrawer() {
        setState({ ...state, drawerOpen: true });
    }

    function closeDrawer() {
        setState({ ...state, drawerOpen: false });
    }

    return (
        <Fragment>
            <SwipeableDrawer anchor="left" open={state.drawerOpen} onOpen={openDrawer} onClose={closeDrawer}>
                <div role="presentation" onClick={closeDrawer}>
                    <List component="nav">
                        <ListItem button onClick={onHomeButton}>
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faHome} />
                            </ListItemIcon>
                            <ListItemText primary="Home" />
                        </ListItem>
                        {/* <ListItem button onClick={onBlogButton}>
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faNewspaper} />
                            </ListItemIcon>
                            <ListItemText primary="Blog" />
                        </ListItem> */}
                        <ListItem button onClick={onSupportButton}>
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faQuestionCircle} />
                            </ListItemIcon>
                            <ListItemText primary="Support" />
                        </ListItem>
                    </List>
                </div>
            </SwipeableDrawer>
            <Dialog open={state.helpDialogOpen} onClose={closeGetHelpDialog} aria-labelledby="gethelp-dialog-title">
                <DialogTitle className={muiClasses['gethelp-title']} id="gethelp-dialog-title">You're not alone. A helping hand is just one tap (or click) away.</DialogTitle>
                <DialogContent>
                    <List>
                        {
                            helpOrgs.map((helpOrg) => {
                                return helpOrg.name === "divider" ? <Divider className={muiClasses['gethelp-org-divider']} key={helpOrg.name} /> : <ListItem className={muiClasses['gethelp-org']} key={helpOrg.name}>
                                    <ListItemText className={muiClasses['gethelp-org-text']} primary={helpOrg.name} secondary={helpOrg.description} />
                                    <ListItemSecondaryAction>
                                        <Button variant="contained" color="secondary" aria-label="Go to the organization's website" onClick={() => window.open(helpOrg.url)}>Go there</Button>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            })
                        }
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={closeGetHelpDialog} color="secondary">Close</Button>
                </DialogActions>
            </Dialog>
            <AppBar position="sticky">
                <Toolbar className="top-bar">
                    <IconButton className={muiClasses['header-button'] + ' ' + muiClasses['menu-button']} tabIndex={0} aria-label="Open menu" aria-haspopup="menu" onClick={openDrawer}>
                        <FontAwesomeIcon icon={faBars} />
                    </IconButton>
                    <Link className="header-logo" to="/">
                        <img src={HeaderLogo} className="header-logo" alt="Levitate logo"></img>
                    </Link>
                    {/* <Button tabIndex={0} className={muiClasses['header-button'] + ' ' + muiClasses['link-button']} aria-label="Link to blog" onClick={onBlogButton}>Blog</Button> */}
                    <Button tabIndex={1} className={muiClasses['header-button'] + ' ' + muiClasses['link-button']} aria-label="Link to support page" onClick={onSupportButton}>Support</Button>
                    <IconButton className={muiClasses['header-button']} tabIndex={2} aria-label="Open Get Help links" aria-haspopup="dialog" onClick={openGetHelpDialog}>
                        <FontAwesomeIcon icon={faExclamation} />
                    </IconButton>
                </Toolbar>
            </AppBar>
        </Fragment>
    );
}

export default Header;