import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import { httpsCallable } from "firebase/functions";
import { collection, getDocs } from "firebase/firestore";
import { initAndGetFunctions, initAndGetFirestore } from './firebase';
import {
    SupportArticleCategory,
    SupportArticleCategoryRawData,
} from "./types/supportTypes";
import { FormField } from './types/formTypes';
import Form from './Form';
import './support.css';

interface SupportState {
    articlesLoading: boolean;
    categoryDrawerOpen: boolean;
    selectedCategory: number;
    categories: SupportArticleCategory[]
}

function Support() {
    const [supportState, setSupportState] = useState<SupportState>({
        articlesLoading: true,
        categoryDrawerOpen: false,
        selectedCategory: 0,
        categories: []
    });
    const supportFormFields: FormField[] = [
        {
            label: "Name",
            helpText: "e.g. Joe Bloggs",
            type: "Text"
        },
        {
            label: "Email",
            helpText: "e.g. joe.bloggs@gmail.com",
            type: "Email"
        },
        {
            label: "Message Title",
            helpText: "e.g. Question about the Levitate app",
            type: "Text"
        },
        {
            label: "Message",
            helpText: "e.g. Hi, I have a question about the Levitate app. I saw that the Safe Haven feature...",
            type: "TextArea"
        }
    ]
    const useStyles = makeStyles({
        "sa-article-text": {
            '& :last-child': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }
        },
        "sa-topic-selected": {
            color: '#673ab7',
            backgroundColor: 'rgba(179,136,255, 0.2) !important'
        }
    });
    const muiClasses = useStyles();
    const router = useHistory();

    async function supportFormAction(fieldValues: string[]) {
        const functions = await initAndGetFunctions();
        const supportMessageFunction = httpsCallable(functions, 'supportMessage');
        return supportMessageFunction(
            {
                name: fieldValues[0],
                email: fieldValues[1],
                subject: fieldValues[2],
                message: fieldValues[3]
            }
        );
    }

    async function getSupportArticles() {
        const firestore = await initAndGetFirestore();
        const supportCollection = collection(firestore, "support");
            
        return getDocs(supportCollection).then((snapshot) => {
            const categories: SupportArticleCategory[] = [];
            snapshot.forEach((doc) => {
                const categoryData = doc.data() as SupportArticleCategoryRawData; // TODO: how do we really do this?

                const articles = categoryData.articles.map((rawArticle) => ({
                    id: categoryData.articles.indexOf(rawArticle),
                    title: rawArticle.title,
                    content: rawArticle.content,
                    category: categoryData.catName,
                    lastUpdated: rawArticle.lastUpdated.toDate().toDateString()
                }));

                categories.push({
                    id: snapshot.docs.findIndex(snapshotDoc => snapshotDoc.id === doc.id),
                    name: categoryData.catName,
                    articles: articles
                });
            });

            return categories;
        });
    }

    useEffect(() => {
        getSupportArticles().then((categories) => {
            setSupportState((currentSupportState) => ({
                ...currentSupportState,
                articlesLoading: false,
                categoryDrawerOpen: true,
                categories: categories,
            }));
        });
    }, []);

    return (
        <div className="App">
            <div className="main-container">
                <div className="lev-card header-card support-header-card">
                    <h1 className="header-card-title">Support</h1>
                </div>
                <div className="lev-card" style={{paddingBottom: "30px"}}>
                    <h1>These might answer your question</h1>
                    <p>If not, you can click the chat button in the bottom-right corner or send us an email using the Contact Us form at the bottom of the page.</p>
                    <br></br>
                    <div className="sa-container raised-card">
                        <div className={"sa-topics" + (!supportState.categoryDrawerOpen ? " sa-topics-closed" : "")}>
                            <List>
                                {!supportState.articlesLoading && supportState.categories.map((category) => {
                                    const selected = supportState.selectedCategory === category.id;
                                    return <ListItem key={category.id} className={selected === true ? muiClasses['sa-topic-selected'] : ""} button selected={selected} onClick={() => setSupportState({ ...supportState, categoryDrawerOpen: false, selectedCategory: category.id })}>
                                        <ListItemText primary={category.name} />
                                    </ListItem>
                                })}
                            </List>
                        </div>
                        <div className="sa-articles">
                            {
                                supportState.articlesLoading ? <div className="sa-loading-container">
                                    <div className="sa-loading">
                                        <LinearProgress />
                                    </div>
                                </div> : <List>
                                    {
                                        supportState.categories[supportState.selectedCategory].articles.map((article) => {
                                            return <ListItem key={article.id} button onClick={() => router.push(`/support/article?category=${supportState.selectedCategory}&article=${article.id}`)}>
                                                <ListItemText className={muiClasses['sa-article-text']} primary={article.title} secondary={article.content} />
                                            </ListItem>
                                        })
                                    }
                                </List>
                            }
                        </div>
                        <div className={"sa-switcher" + (!supportState.categoryDrawerOpen ? " sa-switcher-closed" : "")}>
                            <button onClick={() => setSupportState({ ...supportState, categoryDrawerOpen: !supportState.categoryDrawerOpen })}>
                                <div className="sa-switcher-line"></div>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="lev-card" style={{paddingBottom: "30px"}}>
                    <h1>Contact us</h1>
                    <p>Have a question? Send us a message and we'll get back to you as soon as we can.</p>
                    <br></br>
                    <Form fields={supportFormFields} actionName="Send" action={supportFormAction} />
                </div>
            </div>
        </div>
    );
}

export default Support;