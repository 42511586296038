import React, { useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faEnvelopeOpenText, faMobileAlt, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import WebsitePrivacyPolicy from './WebsitePrivacyPolicy';
import NewsletterPrivacyPolicy from './NewsletterPrivacyPolicy';
import AppPrivacyPolicy from './AppPrivacyPolicy';
import './privacyPolicies.css';

function PrivacyPolicies() {
    const policyNames = ["Website Privacy Policy", "Newsletter Privacy Policy", "App Privacy Policy"];
    const [selectedPolicy, setSelectedPolicy] = useState(-1);

    useEffect(() => {
        var query = new URLSearchParams(window.location.search);
        const qPolicy = query.get("policy");
        if (qPolicy !== null) {
            const policyIndex = Number.parseInt(qPolicy);
            setSelectedPolicy(policyIndex);
        }
    }, []);

    return (
        <div className="App">
            <div className="main-container">
                <div className="lev-card header-card policy-header-card">
                    <h1 className="header-card-title">Privacy Policies</h1>
                </div>
                <div className="lev-card" style={{paddingBottom: "30px"}}>
                    <div>
                        {
                            selectedPolicy !== -1 && <IconButton onClick={() => setSelectedPolicy(-1)} aria-label="Back">
                                <FontAwesomeIcon className="policy-back-button" icon={faArrowCircleLeft} />
                            </IconButton>
                        }
                        <h1 className="policy-title">{selectedPolicy !== -1 ? policyNames[selectedPolicy] : "Our Privacy Policies"}</h1>
                    </div>
                    <br></br>
                    {
                        selectedPolicy === 0
                            ? <WebsitePrivacyPolicy />
                            : selectedPolicy === 1
                            ? <NewsletterPrivacyPolicy />
                            : selectedPolicy === 2
                            ? <AppPrivacyPolicy />
                            : <div>
                                <button className="policy-button" onClick={() => setSelectedPolicy(0)}>
                                    <div className="policy-container">
                                        <div className="policy-image">
                                            <FontAwesomeIcon icon={faGlobe} />
                                        </div>
                                        <div className="policy-label">
                                            <p>Website Privacy Policy</p>
                                        </div>
                                    </div>
                                </button>
                                <button className="policy-button" onClick={() => setSelectedPolicy(1)}>
                                    <div className="policy-container">
                                        <div className="policy-image">
                                            <FontAwesomeIcon icon={faEnvelopeOpenText} />
                                        </div>
                                        <div className="policy-label">
                                            <p>Newsletter Privacy Policy</p>
                                        </div>
                                    </div>
                                </button>
                                <button className="policy-button" onClick={() => setSelectedPolicy(2)}>
                                    <div className="policy-container">
                                        <div className="policy-image">
                                            <FontAwesomeIcon icon={faMobileAlt} />
                                        </div>
                                        <div className="policy-label">
                                            <p>App Privacy Policy</p>
                                        </div>
                                    </div>
                                </button>
                            </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default PrivacyPolicies;