import React, { useEffect } from 'react';
import './cookies.css';

function CookiesPage() {
    useEffect(() => {
        // Only insert script on getlevitate.co.uk, as we're using CookiePro on getlevitate.com
        if (window.location.hostname.endsWith(".co.uk")) {
            var cdScript = document.createElement("script");
            cdScript.id = "CookieDeclaration";
            cdScript.src="https://consent.cookiebot.com/1e20f24e-7500-4b1c-a67b-785fbec19139/cd.js";
            cdScript.type="text/javascript";
            cdScript.async = true;

            document.getElementById("cookie-declaration")?.appendChild(cdScript);
        }
    });

    return (
        <div className="App">
            <div className="main-container">
                <div className="lev-card header-card cookies-header-card">
                    <h1 className="header-card-title">Cookies</h1>
                </div>
                <div className="lev-card" style={{paddingBottom: "30px"}}>
                    <h2>Cookie Declaration</h2>
                    <br></br>
                    {/* On getlevitate.co.uk contains CookieBot script, on getlevitate.com used to display CookiePro cookie list */}
                    <div id={window.location.hostname.endsWith(".co.uk") ? "cookie-declaration" : "ot-sdk-cookie-policy"}></div>
                    {
                        !(window.location.hostname.endsWith(".co.uk")) && 
                            <button id="ot-sdk-btn" className="ot-sdk-show-settings">Cookie Settings</button>
                    }
                </div>
            </div>
        </div>
    );
}

export default CookiesPage;