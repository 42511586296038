import React from 'react';

function NewsletterPrivacyPolicy() {
    return (
        <p className="policy-text">
            This Privacy Policy describes how your personal information is collected, used, and shared when you sign up to the Levitate newsletter on getlevitate.co.uk or getlevitate.com (the “Site”).

            <br /><br /><b>PERSONAL INFORMATION WE COLLECT</b><br />
            When you enter your your email address into the newsletter signup form on our website, we collect your email address. Additionally, our newsletter signup form, embedded in our website, may automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. We refer to this information as “Collected Information.”

            <br /><br /><b>HOW DO WE USE YOUR PERSONAL INFORMATION?</b><br />
            We use the Personal Information that we collect to send you marketing emails about Levitate products to the email address you entered into the newsletter signup form. You may click the Unsubscribe button in any of our emails to stop receiving our marketing emails and to have your email address deleted from our records.

            <br /><br /><b>SHARING YOUR PERSONAL INFORMATION</b><br />
            We use SendGrid to send you marketing emails. This means means that we will share your email address with SendGrid so that they can store your email address and use your email address to send our marketing emails to you. For more information, you can find SendGrid's <a href="https://sendgrid.com/policies/privacy/services-privacy-policy">privacy policy here.</a>
            <br /><br />SendGrid is based in the United States. This means that, if you live outside of the United States, your Personal Information will be transferred to the United States. SendGrid has EU-U.S. Privacy Shield and Swiss-U.S. Privacy Shield certification.
            <br /><br />Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.

            <br /><br /><b>YOUR RIGHTS</b><br />
            You have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.
            Additionally, we note that we are processing your information in order to pursue our legitimate business interests listed above.  Additionally, please note that if you live in Europe, your information will be transferred outside of Europe, including to Canada and the United States.

            <br /><br /><b>DATA RETENTION</b><br />
            When you enter your email address into our newsletter signup form, we will retain your email address for 6 months so that we can send you marketing emails for that time, or until you click the Unsubscribe button in one of our emails, in which case your email address will be deleted from our records then. 

            <br /><br /><b>MINORS</b><br />
            The Site is not intended for individuals under the age of 18. We do not knowingly collect information from individuals under the age of 18. If you are under 18, you should not enter your email address into the newsletter signup form. If you are aware that an individual under the age of 18 has entered their email address into the newsletter signup form, please contact us using the contact details below and we will remove their details from our records swiftly.

            <br /><br /><b>CHANGES</b><br />
            We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons. Once the new privacy policy is posted on our website, it will become effective immediately, and you will be notified of the new privacy policy with an email. We encourage you to check our website regularly to check for updates.

            <br /><br /><b>CONTACT US</b><br />
            For more information about our privacy practices, if you have questions, or if you would like to make a complaint, or if you would like to exercise your rights under GDPR, please contact the Data Controller David Cannon by e-mail at <a href="mailto:david.cannon@getlevitate.co.uk">david.cannon@getlevitate.co.uk</a>.
        </p>
    )
}

export default NewsletterPrivacyPolicy;