import React from 'react';
import { Link } from 'react-router-dom';
import './pageNotFound.css';

function PageNotFound() {
    return (
        <div className="App">
            <div className="main-container">
                <div className="lev-card header-card pnf-header-card">
                    <h1 className="header-card-title">Whoops</h1>
                </div>
                <div className="lev-card" style={{paddingBottom: "30px"}}>
                    <h2>We couldn't find that page</h2>
                    <p>Try checking the URL you entered. If it looks OK, try <Link to="/">going home</Link> to see if what you're looking for is there, or are you looking for our <Link to="/support">Support</Link> page or our <Link to="/privacy">Privacy Policies</Link>?</p>
                </div>
            </div>
        </div>
    );
}

export default PageNotFound;